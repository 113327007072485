import React from "react";
import { Storage } from "aws-amplify";
import Loader from "../Components/Loader";

import VideoGallery from "./VideoGallery";
import VideoS3Map from "../Biz/VideoS3Map";
import AuthUtils from "../Biz/AuthUtils";
import AdminDashboard from "./AdminDashboard";

import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import {
  ListItem,
  ListItemText,
  List,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import { DragHandle } from "@material-ui/icons";

const SortableItem = SortableElement(({ value }) => (
  <ListItem button className="pb_draggable_dialog_element">
    <ListItemText primary={value} />
    <DragHandle />
  </ListItem>
));

const SortableList = SortableContainer(({ items, videoObjectMap }) => {
  return (
    <List>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value}`}
          index={index}
          value={videoObjectMap[value].name}
        />
      ))}
    </List>
  );
});

export default class AppContent extends AuthUtils(React.Component) {
  constructor(props) {
    super(props);
    this.state = { userLoggedIn: false };
    this._validAuthStates = ["signedIn"];

    //Called by AuthUtils
    this.onUserLoggedIn = this.listVideos;
  }

  listVideos() {
    if (this.props.setLoaded) {
      this.props.setLoaded(false);
    }

    Storage.list("", {
      customPrefix: {
        public: "",
      },
    })
      .then(async (result) => {
        let s3Map = new VideoS3Map(result);
        await s3Map.build(false, 5, () => {
          this.setState({ s3Map: s3Map });
        });

        if (this.props.setLoaded) {
          this.props.setLoaded(true);
        }
      })
      .catch((err) => console.log(err));
  }

  componentDidMount() {
    this.checkLoggedIn();
  }

  async finishReorderVideos(confirm) {
    this.props.exitReorderVideos();

    if (confirm) {
      await this.state.s3Map.setSortOrder(this.state.sortOrderItems);
      this.setState({ s3Map: null, sortOrderItems: null });
      this.listVideos();
    } else {
      this.setState({ sortOrderItems: null });
    }
  }

  render() {
    if (!this.state.userLoggedIn) {
      return null;
    }

    if (this.props.page === "default") {
      let videoGallery = this.state.s3Map ? (
        <VideoGallery
          videos={this.state.s3Map.videos}
          user={this.state.user}
          isAdmin={this.isAdmin()}
          allTags={this.state.s3Map.all_tags}
        ></VideoGallery>
      ) : (
        <Loader />
      );

      let reorderVideos = <div></div>;

      if (this.props.showReorderVideos) {
        if (!this.state.sortOrderItems) {
          let items = [...this.state.s3Map.sortOrder];

          for (let i = 0; i < items.length; i++) {
            if (!this.state.s3Map.video_object_map[items[i]]) {
              items.splice(i, 1);
              i--;
            }
          }

          this.state.sortOrderItems = items;
        }

        reorderVideos = (
          <Dialog
            open={this.props.showReorderVideos}
            onClose={() => this.finishReorderVideos()}
            disableBackdropClick
          >
            <DialogTitle>Reorder Videos</DialogTitle>
            <SortableList
              items={this.state.sortOrderItems}
              videoObjectMap={this.state.s3Map.video_object_map}
              onSortEnd={({ oldIndex, newIndex }) => {
                this.setState({
                  sortOrderItems: arrayMove(
                    this.state.sortOrderItems,
                    oldIndex,
                    newIndex
                  ),
                });
                //TODO upload
              }}
            />
            <DialogActions>
              <Button
                onClick={() => this.finishReorderVideos(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => this.finishReorderVideos(true)}
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        );
      }

      return (
        <div>
          {videoGallery}
          {reorderVideos}
        </div>
      );
    } else if (this.props.page === "admin") {
      return <AdminDashboard onClose={this.props.exitAdminDashboard} />;
    }
  }
}
