import React from "react";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ReactLoader from "react-loader-spinner";

export default class Loader extends React.Component {
  render() {
    return <ReactLoader type="ThreeDots" color="#FFFFFF" />;
  }
}
