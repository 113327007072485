import React from "react";
import { ForgotPassword } from "aws-amplify-react";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Grid,
  Button,
} from "@material-ui/core";

import PittLogoHeader from "../Components/PittLogoHeader";

export class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
  }

  showComponent() {
    let inner_grid_props = {
      justify: "center",
      alignItems: "center",
      direction: "row",
    };

    if (this.state.delivery) {
      return (
        <div>
          <PittLogoHeader />
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid container {...inner_grid_props}>
              <Grid item xs={6}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="code">Code</InputLabel>
                  <Input
                    id="code"
                    name="code"
                    key="code"
                    fullWidth={true}
                    type="text"
                    onChange={this.handleInputChange}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                  />
                  <FormHelperText id="code-helper-text">
                    Enter the code sent to your email (check spam)
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container {...inner_grid_props}>
              <Grid item xs={6}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="password">New Password</InputLabel>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    key="password"
                    fullWidth={true}
                    onChange={this.handleInputChange}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                    required={true}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <Grid container {...inner_grid_props}>
              <Grid item xs={3}>
                <Button variant="contained" onClick={() => this.sendCode()}>
                  Resend code
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.submit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <PittLogoHeader />
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid container {...inner_grid_props}>
              <Grid item xs={6}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="email">Email Address</InputLabel>
                  <Input
                    id="email"
                    name="username"
                    key="username"
                    fullWidth={true}
                    type="text"
                    onChange={this.handleInputChange}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                  />
                  <FormHelperText id="email-helper-text">
                    Enter the email you entered when donating
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <Grid container {...inner_grid_props}>
              <Grid item xs={3}>
                <Button variant="contained" onClick={() => this.back()}>
                  Back to Sign In
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.sendCode()}
                >
                  Send Code
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  }

  submit() {
    super.submit();
  }

  back() {
    this.changeState("signIn");
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      if (this.state.delivery) {
        this.submit();
      } else {
        this.sendCode();
      }
    }
  }

  sendCode() {
    super.send();
  }
}
