import { S3 } from "./S3";

export default class Video extends S3 {
  constructor(name, description, thumbnail, id, job, last_modified, tags) {
    super();
    this.name = name;
    this.description = description;
    this.thumbnail = thumbnail;
    this.id = id;
    this.job = job;
    this.last_modified = last_modified;
    this.tags = tags || [];
  }

  async generateLinks() {
    let mp4_key = this.mp4Key;
    let webm_key = this.webmKey;
    this.mp4 = await this.getS3FileURL(mp4_key);
    this.webm = await this.getS3FileURL(webm_key);
  }

  get mp4Key() {
    return this.job.id + "/" + this.id + ".mp4";
  }

  get webmKey() {
    return this.job.id + "/" + this.id + ".webm";
  }

  async modifyDetails(details_changed) {
    let name = details_changed.title || this.name;
    let description = details_changed.description || this.description;
    let tags = details_changed.tags || this.tags;
    let job = this.job;

    let job_video_index = -1;
    for (let i = 0; i < job.body.videos.length; i++) {
      if (job.body.videos[i].id === this.id) {
        job_video_index = i;
      }
    }
    if (job_video_index === -1) {
      alert("Fatal error");
      return;
    }

    let job_obj = job.body.videos[job_video_index];
    job_obj.name = name;
    job_obj.description = description;
    job_obj.tags = tags;
    await this.putS3File(this.job.id + "/details.json", job.body);
  }

  async delete() {
    let job = this.job;

    let job_video_index = -1;
    for (let i = 0; i < job.body.videos.length; i++) {
      if (job.body.videos[i].id === this.id) {
        job_video_index = 0;
      }
    }
    if (job_video_index === -1) {
      alert("Fatal error");
      return;
    }

    let thumbnail_keys = await this.listS3Files(
      this.job.id + "/" + this.id + "/",
      null,
      true
    );

    await this.deleteS3File(this.mp4Key);
    await this.deleteS3File(this.webmKey);

    for (let i = 0; i < thumbnail_keys.length; i++) {
      await this.deleteS3File(thumbnail_keys[i]);
    }

    job.body.videos.splice(job_video_index, 1);

    /* If job is now empty, delete the whole job */
    if (job.body.videos.length === 0) {
      await this.deleteS3File(this.job.id + "/details.json");
    } else {
      /* Otherwise, upload the new job body */
      await this.putS3File(this.job.id + "/details.json", job.body);
    }
  }
}
