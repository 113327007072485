import { v4 as uuid } from "uuid";
import Path from "path";

import { S3 } from "./S3";

export default class AdminS3Map extends S3 {
  constructor(files, refreshFunc) {
    super();
    this.files = files;
    this._refresh = refreshFunc;
    this.files_key_map = {};
    this.created_jobs_files = [];
    this.queued_job_files = [];

    for (let i = 0; i < this.files.length; i++) {
      this.files_key_map[this.files[i].key] = this.files[i];
      if (this.files[i].key.startsWith("__created_jobs/")) {
        this.created_jobs_files.push(this.files[i]);
      }

      if (this.files[i].key.startsWith("__queued_jobs/")) {
        this.queued_job_files.push(this.files[i]);
      }
    }
  }

  /* Returns a hash mapping each file key to whether it is currently used by a job */
  unusedKeys() {
    let map = {};

    let _mapJob = function (job) {
      for (let i = 0; i < job.videos.length; i++) {
        for (let j = 0; j < job.videos[i].clips.length; j++) {
          map[job.videos[i].clips[j]] = true;
        }
      }
    };

    if (this.current_job != null) {
      _mapJob(this.current_job);
    }

    for (let i = 0; i < this.created_jobs.length; i++) {
      _mapJob(this.created_jobs[i]);
    }

    for (let i = 0; i < this.queued_jobs.length; i++) {
      _mapJob(this.queued_jobs[i]);
    }

    let files = this.files;
    for (let i = 0; i < files.length; i++) {
      if (map[files[i].key]) {
        files.splice(i, 1);
        i--;
      }
    }
    return files;
  }

  async enqueueJob(index) {
    await this.putS3File(
      "__queued_jobs/" + this.queued_jobs.length + ".json",
      this.created_jobs[index],
      "publicvideoupload"
    );
    await this.removeCreatedJob(index);
  }

  async dequeueJob(index) {
    await this.createJob(this.queued_jobs[index]);
    await this.removeQueuedJob(index);
    await this._refresh();
  }

  /* Returns a list of single videos and folders of clips that aren't currently used */
  sourceVideos() {
    let sourceFiles = [];

    let rootDirHash = {};

    let files = this.unusedKeys();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (
        file.key.startsWith("__") ||
        file.key.endsWith("/") ||
        file.key.endsWith(".json")
      ) {
        continue;
      }

      /* Single file */
      let dir = Path.dirname(file.key);
      if (dir === ".") {
        sourceFiles.push({
          name: file.key,
          is_multi: false,
          keys: [file.key],
        });
        continue;
      }

      if (rootDirHash[dir]) {
        rootDirHash[dir].keys.push(file.key);
        continue;
      }

      rootDirHash[dir] = {
        name: dir,
        is_multi: true,
        keys: [file.key],
      };
      sourceFiles.push(rootDirHash[dir]);
    }

    return sourceFiles;
  }

  async addVideoToJob(index, videoDetails) {
    this.created_jobs[index].videos.push(videoDetails);
    await this.putS3File(
      "__created_jobs/" + index + ".json",
      this.created_jobs[index],
      "publicvideoupload"
    );
    await this._refresh();
  }

  async removeVideoFromJob(index, videoIndex) {
    this.created_jobs[index].videos.splice(videoIndex, 1);
    await this.putS3File(
      "__created_jobs/" + index + ".json",
      this.created_jobs[index],
      "publicvideoupload"
    );
    await this._refresh();
  }

  async createJob(job_data) {
    if (!job_data) {
      job_data = {
        id: uuid(),
        videos: [],
      };
    }
    await this.putS3File(
      "__created_jobs/" + this.created_jobs_files.length + ".json",
      job_data,
      "publicvideoupload"
    );
    this.created_jobs.push(job_data);
  }

  async makeJobPublicAndDeleteSourceFiles(job) {
    let job_data = job.body;
    job_data.hidden = false;
    /* Make videos public */
    await this.putS3File(job.file.key, job_data);

    /* Delete source clips */
    for (let i = 0; i < job_data.videos.length; i++) {
      for (let j = 0; j < job_data.videos[i].clips.length; j++) {
        let clip_key = job_data.videos[i].clips[j];
        await this.deleteS3File(clip_key, "publicvideoupload");
      }
    }
    window.location.reload(true);
  }

  async removeQueuedJob(index) {
    for (let i = index; i < this.queued_job_files.length; i++) {
      await this.deleteS3File(
        this.queued_job_files[i].key,
        "publicvideoupload"
      );
    }

    for (let i = index + 1; i < this.queued_jobs.length; i++) {
      await this.putS3File(
        "__queued_jobs/" + (i - 1) + ".json",
        this.queued_jobs[i],
        "publicvideoupload"
      );
    }

    this.queued_jobs.splice(index, 1);
    this.queued_job_files.splice(index, 1);
  }

  async removeCreatedJob(index) {
    for (let i = index; i < this.created_jobs_files.length; i++) {
      await this.deleteS3File(
        this.created_jobs_files[i].key,
        "publicvideoupload"
      );
    }

    for (let i = index + 1; i < this.created_jobs.length; i++) {
      await this.putS3File(
        "__created_jobs/" + (i - 1) + ".json",
        this.created_jobs[i],
        "publicvideoupload"
      );
    }

    this.created_jobs.splice(index, 1);
    this.created_jobs_files.splice(index, 1);
    await this._refresh();
  }

  async build() {
    /* Check if there is a running job (next_job.json exists) */
    if (this.files_key_map["next_job.json"]) {
      let file = this.files_key_map["next_job.json"];
      this.current_job = (
        await this.getS3File(file.key, "publicvideoupload")
      ).Body;
    }

    this.created_jobs = [];

    for (let i = 0; i < this.created_jobs_files.length; i++) {
      this.created_jobs.push(
        (
          await this.getS3File(
            this.created_jobs_files[i].key,
            "publicvideoupload"
          )
        ).Body
      );
    }

    this.queued_jobs = [];

    for (let i = 0; i < this.queued_job_files.length; i++) {
      this.queued_jobs.push(
        (
          await this.getS3File(
            this.queued_job_files[i].key,
            "publicvideoupload"
          )
        ).Body
      );
    }
  }
}
