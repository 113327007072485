import React from "react";

import { Storage, Auth } from "aws-amplify";
import Lambda from "aws-sdk/clients/lambda";

import AuthUtils from "../Biz/AuthUtils";
import { S3_Mixin } from "../Biz/S3";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  Input,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  TextField,
  CardActions,
} from "@material-ui/core";
import { Close, DragHandle, Refresh } from "@material-ui/icons";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

import Loader from "../Components/Loader";
import AdminS3Map from "../Biz/AdminS3Map";
import VideoS3Map from "../Biz/VideoS3Map";
import VideoThumbnailItem from "../Components/VideoThumbnailItem";

const SortableItem = SortableElement(({ value }) => (
  <ListItem button className="pb_draggable_dialog_element">
    <ListItemText primary={value} />
    <DragHandle />
  </ListItem>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <List>
      {items.map((value, index) => (
        <SortableItem key={`item-${value}`} index={index} value={value} />
      ))}
    </List>
  );
});

export default class AdminDashboard extends S3_Mixin(
  AuthUtils(React.Component)
) {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      dialogOpen: false,
      buttonsDisabled: false,
    };
  }

  async componentDidMount() {
    this.refresh();
  }

  async refresh() {
    await new Promise((r) => setTimeout(r, 1000));
    let files = await Storage.list("", {
      customPrefix: {
        public: "",
      },
      bucket: "publicvideoupload",
    });

    let uploaded_files = await Storage.list("", {
      customPrefix: {
        public: "",
      },
    });

    this.adminS3Map = new AdminS3Map(files, () => {
      this.refresh();
    });
    await this.adminS3Map.build();
    this.videos3Map = new VideoS3Map(uploaded_files);
    await this.videos3Map.build(true);
    this.setState({ loaded: true, buttonsDisabled: false });
  }

  _videoItem(name, description, tags, key, removeButton, jobKey) {
    return (
      <Grid item xs={3} key={key}>
        <Card className="height_100">
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
            <Box marginTop={2}>
              <Typography variant="body2" color="primary" component="p">
                {tags.join(" | ")}
              </Typography>
            </Box>
          </CardContent>
          {removeButton ? (
            <CardActions>
              <Button
                size="small"
                color="primary"
                fullWidth
                onClick={() => {
                  this.removeVideoFromJob(name, jobKey, key);
                }}
                disabled={this.state.buttonsDisabled}
              >
                Remove
              </Button>
            </CardActions>
          ) : (
            ""
          )}
        </Card>
      </Grid>
    );
  }

  removeVideoFromJob(videoName, jobKey, videoIndex) {
    this.setState({ buttonsDisabled: true });
    this.openDialog(
      "Are you sure you want to remove " + videoName + " from this job?",
      "",
      "Yes",
      "No",
      async (yes) => {
        if (!yes) {
          this.setState({ buttonsDisabled: false });
          return;
        }

        this.setState({ buttonsDisabled: false });
        await this.adminS3Map.removeVideoFromJob(jobKey, videoIndex);
      }
    );
  }

  async addVideoToJob(jobKey, didError, value) {
    let sourceVideos = this.adminS3Map.sourceVideos();

    let items = [];

    for (let i = 0; i < sourceVideos.length; i++) {
      let v = sourceVideos[i];
      items.push(
        <MenuItem key={i} value={i}>
          {v.name + (v.is_multi ? " (clips to concatenate) " : "")}
        </MenuItem>
      );
    }

    if (items.length === 0) {
      this.openDialog(
        "There are no source files",
        "There were no source files found. Make sure to upload your source files to the publicvideoupload bucket.",
        "Ok",
        "",
        () => {
          this.setState({ buttonsDisabled: false });
        }
      );
      return;
    }

    let select = (
      <Select
        labelId="video-dialog-select-label"
        id="video-dialog-select"
        input={<Input fullWidth />}
        value={value !== undefined ? value : ""}
        onChange={(e) => {
          this.setState({
            selectedVideoValue: e.target.value,
          });
          this.addVideoToJob(jobKey, false, e.target.value);
        }}
      >
        {items}
      </Select>
    );

    let dialogContent = (
      <DialogContent className="pb_min_width_350">
        <FormControl fullWidth error={didError}>
          <InputLabel id="video-dialog-select-label">Source File</InputLabel>
          {select}
          <FormHelperText>{didError ? "Select a video" : ""}</FormHelperText>
        </FormControl>
      </DialogContent>
    );

    this.openDialog(
      "Choose a video",
      "",
      "Submit",
      "Cancel",
      async (yes) => {
        this.addVideoDialogConfirm(jobKey, sourceVideos, yes);
      },
      dialogContent
    );
  }

  addVideoDialogConfirm(jobKey, sourceVideos, yes, items) {
    if (!yes) {
      this.setState({ buttonsDisabled: false });
      return;
    }

    let sourceVideo = sourceVideos[this.state.selectedVideoValue];

    if (sourceVideo.is_multi) {
      if (!items) {
        items = sourceVideo.keys;
      }

      let dialogContent = (
        <SortableList
          items={items}
          onSortEnd={({ oldIndex, newIndex }) => {
            items = arrayMove(items, oldIndex, newIndex);
            this.addVideoDialogConfirm(jobKey, sourceVideos, yes, items);
          }}
        />
      );

      this.openDialog(
        "Order the clips for " + sourceVideo.name,
        "",
        "Submit",
        "Cancel",
        (yes) => {
          if (!yes) {
            this.setState({ buttonsDisabled: false });
            return;
          }

          sourceVideo.keys = items;
          this.addVideoEnterDetails(jobKey, sourceVideo);
        },
        dialogContent
      );
      return;
    } else {
      this.addVideoEnterDetails(jobKey, sourceVideo);
    }
  }

  addVideoEnterDetails(jobKey, sourceVideo, details) {
    if (!details) {
      details = {
        title: {
          value: "",
          error: false,
        },
        description: {
          value: "",
          error: false,
        },
        tags: {
          value: "",
        },
      };
    }

    let dialogContent = (
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="create-video-title"
          name="create-video-title"
          label="Video Title"
          type="text"
          fullWidth
          error={details.title.error}
          defaultValue={details.title.value}
          onChange={(e) => {
            details.title.value = e.target.value;
          }}
        ></TextField>
        <TextField
          autoFocus
          margin="dense"
          id="create-video-description"
          name="create-video-description"
          label="Video Description"
          type="text"
          multiline
          rows={4}
          fullWidth
          error={details.description.error}
          defaultValue={details.description.value}
          onChange={(e) => {
            details.description.value = e.target.value;
          }}
        ></TextField>
        <TextField
          autoFocus
          margin="dense"
          id="create-video-tags"
          name="create-video-tags"
          label="Tags (comma separated)"
          type="text"
          multiline
          rows={4}
          fullWidth
          defaultValue={details.tags.value}
          onChange={(e) => {
            details.tags.value = e.target.value;
          }}
        />
      </DialogContent>
    );
    this.openDialog(
      "Enter title and description for " + sourceVideo.name,
      "",
      "Submit",
      "Cancel",
      (yes) => {
        if (!yes) {
          this.setState({ buttonsDisabled: false });
          return;
        }
        details.title.error = !details.title.value;
        details.description.error = !details.description.value;

        if (!details.title.value || !details.description.value) {
          this.addVideoEnterDetails(jobKey, sourceVideo, details);
          return;
        }

        this.finallyAddVideoToJob(jobKey, sourceVideo, {
          title: details.title.value,
          description: details.description.value,
          tags: details.tags.value.split(","),
        });
      },
      dialogContent
    );
  }

  async finallyAddVideoToJob(jobKey, sourceVideo, details) {
    this.setState({ buttonsDisabled: false, loaded: false });
    await this.adminS3Map.addVideoToJob(jobKey, {
      name: details.title,
      description: details.description,
      tags: details.tags,
      clips: sourceVideo.keys,
    });
  }

  _addVideoItem(jobKey, numVideos) {
    return [
      <Grid item xs={3} key="addVideoItem0">
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          disabled={this.state.buttonsDisabled}
          onClick={() => {
            this.setState({
              buttonsDisabled: true,
              selectedVideoValue: 0,
            });
            this.addVideoToJob(jobKey, false, 0);
          }}
        >
          Add Video To Job
        </Button>
      </Grid>,
      numVideos ? (
        <Grid item xs={3} key="addVideoItem1">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={this.state.buttonsDisabled}
            onClick={() => {
              this.setState({
                buttonsDisabled: true,
                loaded: false,
              });
              this.adminS3Map.enqueueJob(jobKey);
            }}
          >
            Queue Job
          </Button>
        </Grid>
      ) : (
        ""
      ),
    ];
  }

  _JobItem(running, videos, key, queued, completed, completed_job_object) {
    let addVideo =
      running || queued || completed
        ? ""
        : this._addVideoItem(key, videos.length);

    return (
      <Grid item xs={12} key={key}>
        <Box
          m={3}
          bgcolor={
            running
              ? "green"
              : queued
              ? "midnightblue"
              : completed
              ? "black"
              : "gray"
          }
          borderRadius={16}
          paddingX={3}
          paddingTop={completed ? 3 : 0}
          paddingBottom={running ? 3 : 0}
        >
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography variant="h4" className="height_100 center_typography">
                {running
                  ? "Running Job"
                  : queued
                  ? "Queued Job"
                  : completed
                  ? "Completed Job"
                  : "Created Job"}
              </Typography>
            </Grid>
            {videos}
          </Grid>
          <Grid container spacing={3}>
            {addVideo}
            {running || queued || completed ? (
              ""
            ) : (
              <Grid item xs={3} key={1}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => this._removeCreatedJob(key)}
                  disabled={this.state.buttonsDisabled}
                >
                  Remove Job
                </Button>
              </Grid>
            )}
            {queued ? (
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    this.setState({
                      buttonsDisabled: true,
                      loaded: false,
                    });
                    this.adminS3Map.dequeueJob(key);
                  }}
                  disabled={this.state.buttonsDisabled}
                >
                  Dequeue Job
                </Button>
              </Grid>
            ) : (
              ""
            )}
            {completed ? (
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.setState({ buttonsDisabled: true });
                    this.openDialog(
                      "Release this job to the website?",
                      "This will delete the source video files and show any videos associated with this job on the main page",
                      "Yes",
                      "No",
                      async (yes) => {
                        if (!yes) {
                          this.setState({
                            buttonsDisabled: false,
                          });
                          return;
                        }
                        this.setState({
                          buttonsDisabled: false,
                          loaded: false,
                        });
                        await this.adminS3Map.makeJobPublicAndDeleteSourceFiles(
                          completed_job_object
                        );
                      }
                    );
                  }}
                  disabled={this.state.buttonsDisabled}
                >
                  Release to website
                </Button>{" "}
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Box>
      </Grid>
    );
  }

  _removeCreatedJob(index) {
    this.openDialog(
      "Remove this job",
      "Are you sure you want to remove this job? This will not delete the source files.",
      "Yes",
      "No",
      async (yes) => {
        if (yes) {
          this.setState({ loaded: false });
          await this.adminS3Map.removeCreatedJob(index);
        }
      }
    );
  }

  async _createJob() {
    this.setState({ loaded: false });
    await this.adminS3Map.createJob();
    this.refresh();
  }

  openDialog(title, text, yes, no, onClose, optionalDialogcontent) {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogText: text,
      dialogYes: yes,
      dialogNo: no,
      dialogContent: optionalDialogcontent,
      onDialogClose: (yes) => {
        this.setState({ dialogOpen: false });
        onClose(yes);
      },
    });
  }

  buildDialog() {
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={() => this.state.onDialogClose(false)}
        disableBackdropClick
      >
        <DialogTitle>{this.state.dialogTitle}</DialogTitle>
        {this.state.dialogContent ? (
          this.state.dialogContent
        ) : (
          <DialogContent>
            <DialogContentText>{this.state.dialogText}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          {this.state.dialogNo ? (
            <Button
              onClick={() => this.state.onDialogClose(false)}
              color="primary"
            >
              {this.state.dialogNo}
            </Button>
          ) : (
            ""
          )}
          <Button
            onClick={() => this.state.onDialogClose(true)}
            color="primary"
          >
            {this.state.dialogYes}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  buildJobsView() {
    let current_job_dom = <div></div>;

    if (this.adminS3Map.current_job) {
      let current_job = this.adminS3Map.current_job;

      let videos = [];
      for (let i = 0; i < current_job.videos.length; i++) {
        let video = current_job.videos[i];
        videos.push(
          this._videoItem(
            video.name,
            video.description,
            video.tags,
            i,
            false,
            -1
          )
        );
      }

      current_job_dom = this._JobItem(true, videos);
    }

    let queued_jobs = [];
    if (this.adminS3Map.queued_jobs) {
      for (let i = 0; i < this.adminS3Map.queued_jobs.length; i++) {
        let job = this.adminS3Map.queued_jobs[i];

        let videos = [];
        for (let j = 0; j < job.videos.length; j++) {
          let video = job.videos[j];
          videos.push(
            this._videoItem(
              video.name,
              video.description,
              video.tags,
              j,
              false,
              i
            )
          );
        }

        queued_jobs.push(this._JobItem(false, videos, i, true));
      }
    }

    let created_jobs = [];

    if (this.adminS3Map.created_jobs) {
      for (let i = 0; i < this.adminS3Map.created_jobs.length; i++) {
        let job = this.adminS3Map.created_jobs[i];

        let videos = [];
        for (let j = 0; j < job.videos.length; j++) {
          let video = job.videos[j];
          videos.push(
            this._videoItem(
              video.name,
              video.description,
              video.tags,
              j,
              true,
              i
            )
          );
        }

        created_jobs.push(this._JobItem(false, videos, i));
      }
    }

    let add_job_button = (
      <Grid item xs={3}>
        <Box ml={3} mt={3}>
          <Button
            color="primary"
            fullWidth
            variant="outlined"
            onClick={() => this._createJob()}
            disabled={this.state.buttonsDisabled}
          >
            Add Job
          </Button>
        </Box>
      </Grid>
    );

    let start_converter_button =
      this.adminS3Map.queued_jobs.length > 0 && !this.adminS3Map.current_job ? (
        <Grid item xs={3}>
          <Box ml={3} mt={3}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => this.startConverter()}
              disabled={this.state.buttonsDisabled}
            >
              Run Queued Jobs
            </Button>
          </Box>
        </Grid>
      ) : (
        ""
      );

    let completed_jobs = [];

    for (let i = 0; i < this.videos3Map.hidden_jobs_list.length; i++) {
      let job = this.videos3Map.hidden_jobs_list[i];
      let videos = [];
      for (let i = 0; i < job.body.videos.length; i++) {
        videos.push(
          <VideoThumbnailItem
            video={this.videos3Map.video_object_map[job.body.videos[i].id]}
            key={i}
          ></VideoThumbnailItem>
        );
      }

      completed_jobs.push(this._JobItem(false, videos, i, false, true, job));
    }

    return (
      <Grid container>
        {completed_jobs}
        {current_job_dom}
        {queued_jobs}
        {created_jobs}
        {add_job_button}
        {start_converter_button}
      </Grid>
    );
  }

  async startConverter() {
    console.log("Starting converter");

    const credentials = await Auth.currentCredentials();

    this.setState({ buttonsDisabled: true });

    let lambda = new Lambda({
      region: "us-east-2",
      credentials: Auth.essentialCredentials(credentials),
    });
    lambda.invoke(
      {
        FunctionName: "converterlambda-prod",
      },
      (err, data) => {
        this.setState({ buttonsDisabled: false });
        if (err) {
          console.log(err);
          alert(err);
          return;
        }
        if (data) {
          alert("Instance has launched!");
        }
      }
    );
  }

  render() {
    let content = <Loader />;

    if (this.state.loaded) {
      content = this.buildJobsView();
    }

    let dialog = this.buildDialog();

    return (
      <div>
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.props.onClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography variant="h6">Admin Dashboard</Typography>
            <Box ml={3}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ loaded: false });
                  this.refresh();
                }}
                aria-label="close"
              >
                <Refresh />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {content}
        {dialog}
      </div>
    );
  }
}
