import { Auth, Hub } from "aws-amplify";

/* Class provided as a "mix-in". Meaning it will extend a class that is passed in.
   See https://stackoverflow.com/questions/29879267/es6-class-multiple-inheritance
*/
export default (E) =>
  class AuthUtils extends E {
    constructor(props) {
      super(props);

      if (!this.state) {
        this.state = {
          userLoggedIn: false,
        };
      } else {
        this.state.userLoggedIn = false;
      }

      Hub.listen("auth", (data) => {
        const { payload } = data;
        this.onAuthEvent(payload);
      });
    }

    onAuthEvent(payload) {
      this.checkLoggedIn();
    }

    groups() {
      if (!this.state || !this.state.user) {
        return [];
      }

      return this.state.user.signInUserSession.accessToken.payload[
        "cognito:groups"
      ];
    }

    isAdmin() {
      return this.groups().includes("Admin");
    }

    checkLoggedIn(bypassCache) {
      Auth.currentAuthenticatedUser({ bypassCache: bypassCache }).then(
        (user) => {
          if (user) {
            this.setState({ userLoggedIn: true, user: user });
            if (this.onUserLoggedIn) {
              this.onUserLoggedIn();
            }
          } else {
            this.setState({ userLoggedIn: false });
          }
        }
      );
    }
  };
