import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./App.css";
import { Authenticator } from "aws-amplify-react";
import awsconfig from "./aws-exports";
import Amplify from "aws-amplify";
import { CustomSignIn } from "./Components/CustomSignIn";
import { CustomRequireNewPassword } from "./Components/CustomRequireNewPassword";
import { CustomForgotPassword } from "./Components/CustomForgotPassword";
import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  AppBar,
  Toolbar,
  Button,
} from "@material-ui/core";
import AppContent from "./Pages/AppContent";
import AuthUtils from "./Biz/AuthUtils";

Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: "pittbanduploadedvideos",
      region: "us-east-2",
    },
  },
  ...awsconfig,
});

export default class App extends AuthUtils(React.Component) {
  constructor(props) {
    super(props);
    this.checkLoggedIn(true);
  }

  render() {
    let theme = createMuiTheme({
      palette: {
        type: "dark",
        primary: {
          main: "#ffb81c",
        },
        secondary: {
          main: "#003594",
        },
      },
      spacing: 8,
    });
    theme = responsiveFontSizes(theme);

    let auth_theme = {
      toast: { marginTop: "15px", color: "red", fontWeight: 900 },
    };

    let authenticator = (
      <Authenticator theme={auth_theme} hideDefault={true}>
        <CustomSignIn />
        <CustomRequireNewPassword />
        <CustomForgotPassword />
        <AppContent
          page={this.state.showAdminDashboard ? "admin" : "default"}
          exitAdminDashboard={() =>
            this.setState({ showAdminDashboard: false })
          }
          showReorderVideos={this.state.showReorderVideos}
          exitReorderVideos={() => this.setState({ showReorderVideos: false })}
          setLoaded={(loaded) => this.setState({ loaded })}
        />
      </Authenticator>
    );

    let appBar = <div></div>;
    if (this.state.userLoggedIn && this.isAdmin()) {
      appBar = (
        <AppBar position="relative" className="pb_admin_appbar">
          <Toolbar>
            <Button
              color="secondary"
              onClick={() => this.setState({ showAdminDashboard: true })}
            >
              Admin Dashboard
            </Button>
            {
            // Disabled in favor of Appsmith
            /* <Button
              color="secondary"
              onClick={() => this.adjustVideoOrder()}
              disabled={!this.state.loaded}
            >
              Adjust Video Order
            </Button> */}
          </Toolbar>
        </AppBar>
      );
    }

    let content = this.state.showAdminDashboard ? (
      <div className="App">{authenticator}</div>
    ) : (
      <div>
        {appBar}
        <div className="App">{authenticator}</div>
      </div>
    );

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {content}
      </MuiThemeProvider>
    );
  }

  adjustVideoOrder() {
    this.setState({ showReorderVideos: true });
  }
}
