import path from "path";

import Video from "./Video";
import default_thumbnail from "../Images/default_thumbnail.jpg";
import { S3 } from "./S3";

export default class VideoS3Map extends S3 {
  constructor(files) {
    super();
    this.files = files;
    this.videos = [];
    this.files_key_map = {};
    this.video_object_map = {};

    for (let i = 0; i < files.length; i++) {
      this.files_key_map[files[i].key] = files[i];
    }

    this.completed_jobs_list = [];
    this.hidden_jobs_list = [];
    this.all_tags = [];
    this.completed_jobs_hash = {};
  }

  get sortOrder() {
    let sort = [];

    if (this.sortJSON) {
      sort = [...this.sortJSON];
    }

    let unsortedVideos = [...this.videos];

    for (let i = 0; i < unsortedVideos.length; i++) {
      if (sort.includes(unsortedVideos[i].id)) {
        unsortedVideos.splice(i, 1);
        i--;
      } else {
        unsortedVideos[i] = unsortedVideos[i].id;
      }
    }

    unsortedVideos = unsortedVideos.sort((a, b) => {
      return (
        this.video_object_map[b].last_modified -
        this.video_object_map[a].last_modified
      );
    });

    return [...sort, ...unsortedVideos];
  }

  async setSortOrder(sort) {
    this.sortJSON = sort;

    await this.putS3File("_sort.json", sort);
  }

  get sortOrderHash() {
    let sortOrder = this.sortOrder;

    let hash = {};

    for (let i = 0; i < sortOrder.length; i++) {
      hash[sortOrder[i]] = i;
    }

    return hash;
  }

  async _retrieve_thumbnails(list) {
    /* Retrieve video thumnails */
    for (let i = 0; i < list.length; i++) {
      let job = list[i];
      let body = job.body;
      for (let j = 0; j < body.videos.length; j++) {
        let video_details = body.videos[j];
        let { name, description, id, tags } = video_details;
        tags = tags || [];
        let thumbnail = video_details.thumbnail || "thumbnail_0.png";
        let thumbnail_key = job.id + "/" + video_details.id + "/" + thumbnail;
        if (!this.files_key_map[thumbnail_key]) {
          thumbnail = default_thumbnail;
        } else {
          thumbnail = await this.getS3FileURL(thumbnail_key);
        }
        let video = new Video(
          name,
          description,
          thumbnail,
          id,
          job,
          job.last_modified,
          tags
        );
        this.videos.push(video);
        this.video_object_map[id] = video;

        for (let k = 0; k < tags.length; k++) {
          if (!this.all_tags.includes(tags[k]) && tags[k]) {
            this.all_tags.push(tags[k]);
          }
        }
      }
    }
  };

  async loadBatch(isAdmin, start, size) {
    const batch_jobs_list = [];
    const hidden_batch_jobs_list = [];

    for (let i = start; i < start + size; i++) {
      let file = this.sortedJobDetailsFiles[i];
      if (path.basename(file.key) === "details.json") {
        let job = {};
        job.id = path.dirname(file.key);
        job.file = file;
        if (this.allVideoDetailsFile[job.id]) {
          job.body = this.allVideoDetailsFile[job.id];
        } else {
          job.data = await this.getS3File(file.key);
          job.body = job.data.Body;
        }
        job.hidden = job.body.hidden;
        if (job.hidden) {
          if (isAdmin) {
            this.hidden_jobs_list.push(job);
            hidden_batch_jobs_list.push(job);
          }
          continue;
        }
        this.completed_jobs_list.push(job);
        batch_jobs_list.push(job);
        this.completed_jobs_hash[job.id] = job;
      }
    }

    await this._retrieve_thumbnails(batch_jobs_list);

    let sortOrderHash = this.sortOrderHash;

    this.videos = this.videos.sort((a, b) => {
      return sortOrderHash[a.id] - sortOrderHash[b.id];
    });

    if (isAdmin) {
      await this._retrieve_thumbnails(hidden_batch_jobs_list);
    }
  }

  async build(isAdmin, batchSize, afterBatch) {
    this.sortJSON = (await this.getS3File("_sort.json")).Body;
    batchSize = batchSize || 5;

    const groupedByJob = {};

    for (let i = 0; i < this.files.length; i++) {
      let file = this.files[i];

      const split = file.key.split("/");

      const pathBegin = split[0];

      if (!groupedByJob[pathBegin]) {
        groupedByJob[pathBegin] = {};
      }

      if (path.basename(file.key) === "details.json") {
        groupedByJob[pathBegin].detailsFile = file;
      } else {
        if (!groupedByJob[pathBegin].otherFiles) {
          groupedByJob[pathBegin].otherFiles = [];
        }

        groupedByJob[pathBegin].otherFiles.push(file);
      }

      if (split.length > 2) {
        groupedByJob[pathBegin].videoID = split[1];
      }
    }

    const groupedByVideoID = {};
    const jobAlreadyGrouped = {};

    Object.keys(groupedByJob).forEach((jobId) => {
      if (jobAlreadyGrouped[jobId]) {
        console.log("ERROR: More than one video per job?")
        return;
      }

      groupedByVideoID[groupedByJob[jobId].videoID] = groupedByJob[jobId];
      jobAlreadyGrouped[jobId] = true;
    });

    const sortMap = {};

    // Remove any videos that no longer exist from the sortJSON.
    this.sortJSON = this.sortJSON.filter((videoID) => videoID in groupedByVideoID);

    for (let i = 0; i < this.sortJSON.length; i++) {
      sortMap[this.sortJSON[i]] = i;
    }

    this.sortedJobDetailsFiles = [];

    Object.keys(groupedByVideoID).sort((a, b) => {
      let aWeight = a in sortMap ? sortMap[a] : Number.MAX_VALUE;
      let bWeight = b in sortMap ? sortMap[b] : Number.MAX_VALUE;

      return aWeight - bWeight;
    }).forEach((videoID) => {
      if (!groupedByVideoID[videoID].detailsFile) {
        return;
      }

      this.sortedJobDetailsFiles.push(groupedByVideoID[videoID].detailsFile);
    });

    // Download _allVideoDetails.json file and store it as a cache.
    // This was an optimization to avoid downloading all the details.json files.
    // However, the code will still download details.json files if the job is not
    // yet contained in the _allVideoDetails.json file.

    // If noCache query parameter is set, then don't use the cache.
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("noCache") !== null && queryParams.get("noCache") == "true") {
      this.allVideoDetailsFile = {};
    } else {
      this.allVideoDetailsFile = (await this.getS3File("_allVideoDetails.json")).Body;
    }

    /* Download job details.json files */
    for (let i = 0; i < this.sortedJobDetailsFiles.length; i += batchSize) {
      await this.loadBatch(isAdmin, i, Math.min(batchSize, this.sortedJobDetailsFiles.length - i));
      if (afterBatch) {
        await afterBatch();
      }
    }
  }
}
