import React from "react";
import { RequireNewPassword } from "aws-amplify-react";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Grid,
  Button,
} from "@material-ui/core";

import PittLogoHeader from "../Components/PittLogoHeader";

export class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ["requireNewPassword"];
  }

  showComponent() {
    let inner_grid_props = {
      justify: "center",
      alignItems: "center",
      direction: "row",
    };

    return (
      <div>
        <PittLogoHeader />
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid container {...inner_grid_props}>
            <Grid item xs={6}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  key="password"
                  fullWidth={true}
                  onChange={this.handleInputChange}
                  onKeyDown={(e) => this.handleKeyDown(e)}
                  required={true}
                />
              </FormControl>
              <FormHelperText id="new-password-helper-text">
                You are required to change your password
              </FormHelperText>
            </Grid>
          </Grid>
          <br />
          <Grid container {...inner_grid_props}>
            <Grid item xs={3}>
              <Button variant="contained" onClick={() => this.back()}>
                Back to Sign In
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.changePassword()}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  back() {
    this.changeState("signIn");
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.change();
    }
  }

  changePassword() {
    super.change();
  }
}
