import React from "react";
import ReactPlayer from "react-player";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import DangerButton from "./DangerButton";

import AuthUtils from "../Biz/AuthUtils";

export default class VideoPlayerModal extends AuthUtils(React.Component) {
  constructor(props) {
    super(props);
    this.state = {
      pip: false,
      user: props.user,
      showRemovalDialog: false,
      showDetailsDialog: false,
    };
    /* No need to do this.checkLoggedIn because our user is passed in as a prop. */
    /* We do, however, have access to all methods inside AuthUtils. */

    this.videoDetailsModified = {};
  }

  onEnablePIP() {
    this.setState({ pip: true });
  }

  onDisablePIP() {
    this.setState({ pip: false });
  }

  async removalDialogClose(confirm) {
    this.setState({ showRemovalDialog: false });
    if (confirm) {
      await this.props.video.delete();
      window.location.reload(false);
    }
  }

  async detailsDialogClose(confirm) {
    if (confirm) {
      await this.props.video.modifyDetails(this.videoDetailsModified);
      // Reload with noCache=true
      const url = new URL(window.location.href);
      url.searchParams.set("noCache", true);
      window.location.href = url.href;
      // window.location.reload(false);
    }
    this.setState({ showDetailsDialog: false });
  }

  async copyDirectLink() {
    const url = new URL(window.location.href);

    url.searchParams.set("video", this.props.video.id);

    await navigator.clipboard.writeText(url.href);
  }

  render() {
    let video = this.props.video;
    return (
      <div>
        {this.isAdmin() ? (
          <Dialog open={this.state.showRemovalDialog} disableBackdropClick>
            <DialogTitle>
              Are you sure you want to remove {video.name}?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                This will also delete the video files from storage.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.removalDialogClose(false)}
                color="primary"
              >
                No
              </Button>
              <Button
                onClick={() => this.removalDialogClose(true)}
                color="primary"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        {this.isAdmin() ? (
          <Dialog open={this.state.showDetailsDialog} disableBackdropClick>
            <DialogTitle>Edit Video Details</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="modify-video-title"
                name="modify-video-title"
                label="Video Title"
                type="text"
                fullWidth
                // error={details.title.error}
                defaultValue={video.name}
                onChange={(e) => {
                  this.videoDetailsModified.title = e.target.value;
                }}
              />
              <TextField
                autoFocus
                margin="dense"
                id="modify-video-description"
                name="modify-video-description"
                label="Video Description"
                type="text"
                multiline
                rows={4}
                fullWidth
                // error={details.description.error}
                defaultValue={video.description}
                onChange={(e) => {
                  this.videoDetailsModified.description = e.target.value;
                }}
              />
              <TextField
                autoFocus
                margin="dense"
                id="modify-video-tags"
                name="modify-video-tags"
                label="Tags (comma separated)"
                type="text"
                multiline
                rows={4}
                fullWidth
                // error={details.description.error}
                defaultValue={video.tags.join(",")}
                onChange={(e) => {
                  this.videoDetailsModified.tags = e.target.value.split(",");
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.detailsDialogClose(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => this.detailsDialogClose(true)}
                color="primary"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.props.onClose}
          className={this.state.pip ? "hidden" : ""}
        >
          <AppBar position="relative">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.props.onClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <Typography variant="h6">{video.name}</Typography>
              {this.isAdmin() ? (
                <Box ml={3}>
                  <DangerButton
                    color="secondary"
                    onClick={() => this.setState({ showRemovalDialog: true })}
                  >
                    Remove Video
                  </DangerButton>
                </Box>
              ) : (
                ""
              )}
              {this.isAdmin() ? (
                <Box ml={3}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => this.setState({ showDetailsDialog: true })}
                  >
                    Edit Video Details
                  </Button>
                </Box>
              ) : (
                ""
              )}
              <Box ml={3}>
                <Button
                  color="secondary"
                  onClick={() => { this.copyDirectLink() }}
                >
                  Copy Direct Link
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
          <div className="pb_video_player_wrapper">
            <ReactPlayer
              url={[
                { src: video.webm, type: "video/webm" },
                { src: video.mp4, tyoe: "video/mp4" },
              ]}
              playing={true}
              loop={false}
              controls={true}
              width="100%"
              height="calc(100vh - 100px)"
              className="pb_video"
              onEnablePIP={() => this.onEnablePIP()}
              onDisablePIP={() => this.onDisablePIP()}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    onContextMenu: (e) => e.preventDefault(),
                  },
                },
              }}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}
