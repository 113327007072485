import { Storage } from "aws-amplify";

function _S3Options(altBucket, func) {
  let options = {
    customPrefix: {
      public: "",
    },
  };
  if (altBucket) {
    options.bucket = altBucket;
  }

  if (func === "get") {
    if (altBucket === "publicvideoupload") {
      options.expires = 1;
    } else {
      options.expires = 120;
    }
  }

  return options;
}

async function _getS3FileURL(key, altBucket) {
  return await Storage.get(key, { expires: 0, ..._S3Options(altBucket) });
}

async function _getS3File(key, altBucket) {
  let data = await Storage.get(key, {
    download: true,
    ..._S3Options(altBucket, "get"),
  });
  data.Body = JSON.parse(await data.Body.text());
  return data;
}

async function _putS3File(key, data, altBucket) {
  return await Storage.put(key, data, _S3Options(altBucket));
}

async function _deleteS3File(key, altBucket) {
  return await Storage.remove(key, _S3Options(altBucket));
}

async function _listS3Files(prefix, altBucket, justKeys) {
  let res = await Storage.list(prefix, _S3Options(altBucket));
  if (!justKeys) {
    return res;
  }

  let keys = [];

  for (let i = 0; i < res.length; i++) {
    keys.push(res[i].key);
  }

  return keys;
}

function _init(c) {
  c.getS3File = _getS3File;
  c.getS3FileURL = _getS3FileURL;
  c.putS3File = _putS3File;
  c.deleteS3File = _deleteS3File;
  c.listS3Files = _listS3Files;
}

export class S3 {
  constructor() {
    _init(this);
  }
}

export const S3_Mixin = (E) =>
  class S3_Mix extends E {
    constructor(props) {
      super(props);

      _init(this);
    }
  };
