import React from "react";

import {
  Grid,
  Drawer,
  List,
  ListItem,
  Toolbar,
  FormControlLabel,
  Checkbox,
  TextField
} from "@material-ui/core";

import VideoThumbnailItem from "../Components/VideoThumbnailItem";

import PittLogoHeader from "../Components/PittLogoHeader";

export default class VideoGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      search: "",
    };
  }

  setTag(tag, selected) {
    let tags = this.state.tags;
    if (selected) {
      tags.push(tag);
    } else {
      tags = tags.filter((e) => e !== tag);
    }
    this.setState({
      tags,
    });
  }

  render() {
    let videoCards = [];

    outer: for (let i = 0; i < this.props.videos.length; i++) {
      if (this.state.tags.length > 0) {
        for (let j = 0; j < this.state.tags.length; j++) {
          let tag = this.state.tags[j];
          let tags = this.props.videos[i].tags;
          if (!tags.includes(tag)) {
            continue outer;
          }
        }
      }

      if (this.state.search.length > 0) {
        let search = this.state.search.toLowerCase();
        let title = this.props.videos[i].name.toLowerCase();
        let description = this.props.videos[i].description.toLowerCase();
        if (
          title.indexOf(search) === -1 &&
          description.indexOf(search) === -1
        ) {
          continue;
        }
      }

      videoCards.push(
        <Grid item lg={4} key={i} className="video-card">
          <VideoThumbnailItem
            video={this.props.videos[i]}
            user={this.props.user}
          ></VideoThumbnailItem>
        </Grid>
      );
    }

    return (
      <div className="pb_video_gallery_root">
        {this.props.allTags.length > 0 ? (
          <Drawer
            variant="permanent"
            className="pb_drawer_parent"
            classes={{ paper: "pb_tags_drawer" }}
          >
            {this.props.isAdmin ? <Toolbar /> : ""}
            <List>
              {this.props.allTags.map((text, index) => (
                <ListItem key={text}>
                  <FormControlLabel
                    value={text}
                    control={<Checkbox color="primary" />}
                    label={text}
                    labelPlacement="end"
                    onChange={(e) => {
                      this.setTag(text, e.target.checked);
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Drawer>
        ) : (
          ""
        )}
        <div style={{ width: "100%" }}>
          <Grid container justify="center">
            <Grid item xs={10}>
              <PittLogoHeader />
            </Grid>
            <Grid item xs={10} style={{ marginBottom: "20px" }}>
              <TextField
                type="text"
                placeholder="Search... (Try '1986' or 'Stadium Review', etc.)"
                style={{ width: "75%" }}
                variant="filled"
                onChange={(e) => {
                  this.setState({ search: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={3}>
                {videoCards}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
