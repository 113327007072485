import React from "react";
import { SignIn } from "aws-amplify-react";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Grid,
  Button,
} from "@material-ui/core";

import PittLogoHeader from "../Components/PittLogoHeader";

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut"];
  }

  showComponent() {
    let inner_grid_props = {
      justify: "center",
      alignItems: "center",
      direction: "row",
    };

    return (
      <div>
        <PittLogoHeader />
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid container {...inner_grid_props}>
            <Grid item xs={6}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input
                  id="email"
                  name="username"
                  key="username"
                  fullWidth={true}
                  type="text"
                  onChange={this.handleInputChange}
                  onKeyDown={(e) => this.handleKeyDown(e)}
                />
                <FormHelperText id="email-helper-text">
                  Enter the email you entered when donating
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container {...inner_grid_props}>
            <Grid item xs={6}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  key="password"
                  fullWidth={true}
                  onChange={this.handleInputChange}
                  onKeyDown={(e) => this.handleKeyDown(e)}
                  required={true}
                />
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Grid container {...inner_grid_props}>
            <Grid item xs={3}>
              <Button variant="contained" onClick={() => this.forgotPassword()}>
                Forgot Password
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.login()}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.login();
    }
  }

  forgotPassword() {
    this.changeState("forgotPassword");
  }

  login() {
    if (!this.inputs.password) {
      return;
    }
    super.signIn();
  }
}
