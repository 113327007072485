import React from "react";

import logo from "../logo.svg";
import { Grid } from "@material-ui/core";

export default class PittLogoHeader extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ marginTop: "15px", marginBottom: "15px" }}
      >
        <img src={logo} className="center" alt="" width="350px" />
      </Grid>
    );
  }
}
