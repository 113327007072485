import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@material-ui/core";

import VideoPlayerModal from "./VideoPlayerModal";

export default class VideoThumbnailItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      thumbnail: props.video.thumbnail,
      title: props.video.name,
      description: props.video.description,
      hidden: props.video.hidden,
      tags: props.video.tags.join(" | "),
      playing: false,
    };

    const url = new URL(window.location.href);

    if (url.searchParams.get("video") === props.video.id) {
      this.startVideo();
    }

  }

  async startVideo() {
    await this.props.video.generateLinks();
    this.setState({ playing: true });
  }

  async onClick() {
    this.startVideo();

    // Set url in case it is manually copied
    const url = new URL(window.location.href);
    url.searchParams.set("video", this.props.video.id);
    window.history.pushState({}, "", url);
  }

  onClose() {
    this.setState({ playing: false });

    // Remove url in case it is manually copied
    const url = new URL(window.location.href);
    url.searchParams.delete("video");
    window.history.pushState({}, "", url);
  }

  render() {
    return (
      <div className="height_100">
        <Card className="height_100">
          <CardActionArea className="height_100" onClick={() => this.onClick()}>
            <CardMedia
              className={"pb_thumbnail"}
              image={this.state.thumbnail}
              title={this.state.title}
            />
            <CardContent className="height_100">
              <Typography gutterBottom variant="h5" component="h2">
                {this.state.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {this.state.description}
              </Typography>
              <Box marginTop={2}>
                <Typography variant="body2" color="primary" component="p">
                  {this.state.tags}
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
        <VideoPlayerModal
          open={this.state.playing}
          video={this.props.video}
          user={this.props.user}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}
